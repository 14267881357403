@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

/* Hero Section */
.contact-hero {
    width: 100%;
    height: 500px;
    background-image: url('../../../public//inner-banner-bg.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}

.contact-hero .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(170deg, #3954ff 0, #617eff 50%, #c0e0ff 100%);
}

.contact-hero .hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px!important;
    width: 100%;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
}

.hero-text {
    max-width: 600px;
}

.hero-text h1 {
    font-size: 3rem;
    margin: 0;
    font-weight: 700;
    letter-spacing: -1px;
}

.hero-text p {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #e0e0e0;
}

.hero-image img {
    max-width: 400px;
    height: auto;
    border-radius: 12px;
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    background: #f8f9fd;
}

.contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 700px;
}

.contact-card {
    background: white;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease-in-out;
    width: 320px;
    /* max-width: 320px; */
}

.contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.contact-card .icon {
    font-size: 24px;
    color: #3954ff;
    margin-bottom: 10px;
}

.contact-card h3 {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin-bottom: 8px;
}

.contact-card p {
    font-size: 14px;
    color: #555;
    margin: 3px 0;
}

.map-container {
    max-width: 500px;
    width: 100%;
    height: 370px;
    /* border-radius: 10px; */
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.contact-form-container {
    max-width: 1100px;
    margin: 50px auto;
    padding: 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.contact-form-container h2 {
    font-size: 40px;
    font-weight: 600;
    color: #2d1e66;
    margin-bottom: 40px;
}

.form-row {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.contact-form-container input,
.contact-form-container textarea {
    width: 100%;
    padding: 20px!important;
    border: none;
    background: #f8f8f8;
    /* border-radius: 5px; */
    font-size: 14px;
}

textarea {
    height: 190px;
    resize: none;
}

.contact_btn {
    background: linear-gradient(159deg, #3954ff 0, #617eff 75%, #c0e0ff 100%);
    color: white!important;
    padding: 12px 25px!important;
    border: none;
    font-size: 16px!important;
    /* border-radius: 20px; */
    cursor: pointer!important;
    transition: 0.3s ease-in-out;
    margin-top: 20px!important;
}

.contact_btn:hover {
    background: linear-gradient(210deg, #3954ff 0, #617eff 75%, #c0e0ff 100%);
}


@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .contact-info {
        grid-template-columns: 1fr;
        max-width: 100%;
    }

    .map-container {
        max-width: 100%;
    }

    .form-row {
        flex-direction: column;
    }

    .hero-content {
        flex-direction: column;
        text-align: center;
    }

    .hero-text h1 {
        font-size: 2rem;
    }

    .hero-image img {
        max-width: 100%;
        margin-top: 1rem;
    }
}